.contact-form {
	max-width: 500px;
	margin: 20px auto;
	padding: 20px;
	background: #f9f9f9;
	border: 1px solid #e1e1e1;
	border-radius: 4px;
}

.contact-form form {
	display: flex;
	flex-direction: column;
}

.contact-form input[type="text"],
  .contact-form input[type="email"],
  .contact-form input[type="tel"], /* Add for Phone input type */
  .contact-form textarea {
	padding: 10px;
	margin-bottom: 10px;
	border: 1px solid #ddd;
	border-radius: 4px;
}

.contact-form textarea {
	resize: vertical;
}

.contact-form button {
	padding: 10px;
	background-color: #007bff;
	color: white;
	border: none;
	border-radius: 4px;
	cursor: pointer;
	transition: background-color 0.3s ease;
}

.contact-form button:hover {
	background-color: #0056b3;
}

/* Add styles for success and error messages */
.contact-form .success-message {
	color: green;
	margin-top: 10px;
}

.contact-form .error-message {
	color: red;
	margin-top: 10px;
}
